import { useEffect, useRef, useState } from 'react';
import style from './GalaList.module.scss';
import PostContact from "../forms/PostContact";
import WriteModal from "../modalwin/WriteModal";
import OpenImg from "./OpenImg";
import NewsService from "../../services/NewsService";

function GalaryList (){

    const images = []

    const containerRef = useRef(null);
    const [activemodal, setActivemodal] = useState(false);
    const [data, setData] = useState('');
    const [more, setMore] = useState(8);
    const [allImgs, setAllImgs] = useState([])
    const postResume = (pos) => {
        setData(pos);
        setActivemodal(true);
    };

    const getGalleryImgs = async () => {
        console.log('функция запускается')
        try {
            const { data } = await NewsService.getGalleryImgs({ capter: 'hopekids' });
            console.log(data)
            if (data) {
		data.reverse()
                setAllImgs(data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getGalleryImgs()
    }, []);

    return (
        <div className={style.main}>
            <WriteModal
                activemodal={activemodal}
                setActivemodal={setActivemodal}
                data={<OpenImg img={data} />}
                setData={setData}
            />
            <div className={style.grid} ref={containerRef}>
                {allImgs.slice(0, more).map((image, index) => (
                    <div
                        key={index}
                        className={style.item}
                        onClick={() => postResume(image.image)}
                    >
                        <img src={`${process.env.REACT_APP_API_URL}${image.image}`} />
                    </div>
                ))}
            </div>
            {allImgs.length > more && (
                <div className={style.more} onClick={() => setMore(more + 8)}>
                    ЕЩЁ
                </div>
            )}
        </div>
    );
}

export default GalaryList;