import style from './styles/VakansiiStyle.module.scss'
import TwoBlocks from "../components/animation/TwoBlocks";
import Footer from "../components/footer/Footer";
import NewsPost from "../components/news/NewsPost";
import SmallHeader from "../components/newheader/SmallHeader";
import {useEffect, useState} from "react";
import FormModal from "../components/modalwin/FormModal";
import PostContact from "../components/forms/PostContact";
import WriteModal from "../components/modalwin/WriteModal";
import PostResume from "../components/forms/PostResume";

function AllNews (){

    const vakansii = [
        {
            name: 'OMEDIA',
            positions: [
                {
                    name: 'Motion-дизайнер',
                    respon: [
                    ],
                    requierments: [
                        'Хорошее визуальное восприятие и способность анализировать композицию, цвета, формы и композиционные решения',
                        'Владение инструментами: знание специализированных программ, таких как Adobe After Effects, Blender, Cinema 4D и других',
                        'Понимание принципов анимации'
                    ],
                    conditions: [
                        'Официальное трудоустройство',
                        'Социальный пакет',
                        '«Белая» заработная плата',
                        'Льготный доступ к предприятиям, входящим в группу компаний OMEDIA!'
                    ],
                    keyskills: [
                    ],
                    open: false
                }
            ]
        },
        {
            name: 'Hope Kids',
            positions: [
                {
                    name: 'Аниматор',
                    respon: [
                    ],
                    requierments: [
                        'Возраст от 16 лет',
                        'Опыт работы желателен',
                        'Общительность и умение находить общий язык с детьми',
                        'Позитивный настрой и любовь к своему делу',
                        'Открытость к новым идеям и способность быстро адаптироваться',
                        'Любовь к детям и желание приносить им радость'
                    ],
                    conditions: [
                        'График работы свободный',
                        'Основная занятость: будни - вечернее время, выходные',
                        'Возможность совмещать с другой работой',
                        'Заработная плата от 15 000'
                    ],
                    keyskills: [
                    ],
                    open: false
                }
            ]
        },
        {
            name: 'Сургутинтерновости',
            positions: [
                {
                    name: 'Бухгалтер',
                    respon: [
                        'Перечень обязанностей на собеседовании'
                    ],
                    requierments: [

                    ],
                    conditions: [
                        'Официальное трудоустройство',
                        'Социальный пакет',
                        '«Белая» заработная плата',
                        'Льготный доступ к предприятиям, входящим в группу компаний OMEDIA!'
                    ],
                    keyskills: [
                    ],
                    open: false
                },
                {
                    name: 'Радиоведущий',
                    respon: [
                        'Подготовка и ведение развлекательных, информационных программ',
                        'Участие в подготовке онлайн и офлайн-акций редакции'
                    ],
                    requierments: [
                        'Безусловная грамотность речи',
                        'Красивый, поставленный голос',
                        'Отсутствие дефектов речи, акцента, говора',
                        'Пунктуальность',
                        'Ответственность',
                        'Готовность развиваться и обучаться',
                        'Владение базовыми компьютерными программами для обработки текста и воспроизведения звука (научим)',
                        'Приветствуется знание программ для редактуры видео (в телефоне)'
                    ],
                    conditions: [
                        'Официальное трудоустройство',
                        'Полная занятость',
                        'График 6/1 (по полдня) или 2/2 (по договоренности)',
                        'Крутой молодой коллектив',
                        'Заработная плата 43 000 рублей после вычета налогов + дополнительная оплата подготовки и ведения коммерческих программ, акций и прочего',
                        'Базовое обучение',
                        'Перспективы карьерного и личностного роста в крупнейшем окружном современном медиахолдинге (ТВ, диджитал, реклама, маркетинг, PR и пр.)',
                        'Офис в центральном районе города'
                    ],
                    keyskills: [
                    ],
                    open: false
                },
                {
                    name: 'Редактор информационных радиопрограмм',
                    respon: [
                        'Написание новостей (ежедневно) и сценариев информационных программ (1-2 раз в неделю)',
                        'Посещение мероприятий по заданию редакции',
                        'Мониторинг информационного пространства, поиск интересных информационных поводов и отработка их',
                        'Проведение интервью (допускается онлайн)'
                    ],
                    requierments: [
                        'Безусловная грамотность речи',
                        'Грамотность написания',
                        'Умение говорить по телефону, проводить интервью',
                        'Желание учиться',
                        'Владение базовыми компьютерными программами для обработки текста и воспроизведения звука (научим)',
                        'Оперативность'
                    ],
                    conditions: [
                    ],
                    keyskills: [
                    ],
                    open: false
                },
                {
                    name: 'Корреспондент',
                    respon: [
                        'Посещение мероприятий по заданию редакции',
                        'Проведение интервью',
                        'Написание собственных новостей, обзоров',
                        'Мониторинг информационного пространства, поиск интересных инфоповодов и героев'
                    ],
                    requierments: [
                        'Опыт работы приветствуется (но не обязателен)',
                        'Грамотность',
                        'Владение базовыми компьютерными программами для обработки текста и воспроизведения видео',
                        'Оперативность',
                        'Коммуникабельность',
                        'Желание учиться'
                    ],
                    conditions: [
                        'Молодая, амбициозная команда',
                        'Социальный пакет',
                        'Льготный доступ к предприятиям, входящим в группу компаний OMEDIA!',
                        'Карьерный рост',
                        'Перспектива открыть собственный телепроект, работать в прямом эфире, стать ведущим на радио',
                        'Офис в центре города',
                        '«Белая» заработная плата',
                        'Возможность учиться у профессионалов прямо на рабочем месте'
                    ],
                    keyskills: [
                    ],
                    open: false
                },
                {
                    name: 'Помощник оператора',
                    respon: [
                        'Съемка сюжетов в новостные программы',
                        'Запись новостных передач в студии',
                        'Работа на прямых трансляциях'
                    ],
                    requierments: [
                    ],
                    conditions: [
                        'Белая заработная плата и оформление по ТК',
                        'Работа с современной техникой',
                        'Обучение с нуля',
                        'Молодой коллектив, готовый помогать и обучать',
                        'Свобода творческой реализации и рост'
                    ],
                    keyskills: [
                    ],
                    open: false
                },
                {
                    name: 'Юрисконсульт',
                    respon: [
                        'Экспертиза, подготовка договорных документов, дополнительные соглашения, протоколы разногласий, письма контрагентам',
                        'Досудебное разрешение споров; претензионно-исковая работа и работа с арбитражными делами (подача исков, подготовка отзывов)',
                        'Правовая оценка документов',
                        'Работа с уставными документами общества'
                    ],
                    requierments: [
                        'Оконченное юридическое образование',
                        'Уверенный пользователь ПК (Word, Excel)',
                        'Уверенный пользователь справочно-правовых систем',
                        'Знание правил ведения деловой переписки',
                        'Грамотная устная и письменная речь',
                        'Коммуникабельность',
                        'Внимательность',
                        'Ответственность',
                        'Оперативность в работе',
                        'Умение продуктивно планировать свое рабочее время',
                        'Пунктуальность'
                    ],
                    conditions: [
                        'Трудоустройство в соответствии с законодательством РФ'
                    ],
                    keyskills: [
                    ],
                    open: false
                },
                {
                    name: 'SMM-специалист',
                    respon: [
                        'Съёмки для соцсетей',
                        'Монтаж видеороликов',
                        'Генерирование идей',
                        'Разработка контент-плана',
                        'Рерайт новостей'
                    ],
                    requierments: [
                        'Знание базовых программ видеомонтажа',
                        'Творческий ум',
                        'Грамотность и умение работать с текстом',
                        'Навыки в графическом дизайне будут преимуществом'
                    ],
                    conditions: [
                        'Белая заработная плата и соблюдение ТК РФ',
                        'Уютный офис и молодой коллектив',
                        'Возможность творческой реализации и роста',
                        'Базовое обучение'
                    ],
                    keyskills: [
                    ],
                    open: false
                },
                {
                    name: 'Менеджер по продажам',
                    respon: [
                        'Высшее образование',
                        'Опыт работы в продажах (желательно рекламы)',
                        'Наличие личного автомобиля'
                    ],
                    requierments: [
                    ],
                    conditions: [
                        'Разъездной характер работы',
                        'Заработная плата обговаривается индивидуально на собеседовании',
                        'Официальное трудоустройство',
                        'Социальный пакет',
                        'Льготный доступ к предприятиям, входящим в группу компаний OMEDIA!'
                    ],
                    keyskills: [
                    ],
                    open: false
                }
            ]
        },
        {
            name: 'Реафарм',
            positions: [
                {
                    name: 'Управляющий на предприятие по выращиванию зелени и салатных культур',
                    respon: [
                        'Общее текущее руководство производственно-хозяйственной деятельностью сельскохозяйственного предприятия',
                        'Руководство коллективом и создание оптимальных условий труда для выполнения производственных планов',
                        'Организация и контроль технологического цикла в производстве',
                        'Организация продаж продукции',
                        'Текущий контроль достаточности уровня запасов сырья',
                        'Внедрение мер по совершенствованию процессов производства',
                        'Повышение эффективности работы предприятия и оптимизация производственных процессов',
                        'Разработка годовых и перспективных планов по растениеводству, составление технологических карт',
                        'Организация выполнения всех технологических операций с подготовительного по заключительный периоды (подготовка посадочного материала, уходные работы, сбор продукции)',
                        'Организация и контроль за соблюдением карантинных и профилактических мер в комплексе, недопущение нарушения фитосанитарных правил',
                        'Выявление неисправностей в работе оборудования, относящегося к комплексу (полив, освещение), организация работ по их устранению'
                    ],
                    requierments: [
                        'Опыт в руководящей должности',
                        'ОБЯЗАТЕЛЕН опыт организации продаж',
                        'Образование желательно сельскохозяйственное (но не обязательно)',
                        'Уверенный пользователь ПК (Word, Excel)'
                    ],
                    conditions: [
                        'Режим работы 5/2',
                        'Оформление согласно ТК РФ',
                        'Заработная плата от 100 000 рублей + премии по результатам работы',
                        'Испытательный срок 3 месяца',
                        'Конечные условия обсуждаются с успешно прошедшими собеседования кандидатами'
                    ],
                    keyskills: [
                        'Ответственность',
                        'Внимательность',
                        'Исполнительность',
                        'Умение работать в режиме многозадачности'
                    ],
                    open: false
                }
            ]
        },
        {
            name: 'Термокомфорт',
            positions: [
                {
                    name: 'Специалист по охране труда и промышленной безопасности (Сургут)',
                    respon: [
                        'Осуществлять контроль над соблюдением на предприятии и в его подразделениях законодательных и иных нормативных правовых актов по охране труда, за предоставлением работникам установленных льгот и компенсаций по условиям труда',
                        'Изучать условия труда на рабочих местах, подготавливать и вносить предложения по разработке и внедрении более совершенных конструкций оградительной техники, предохранительных и блокировочных устройств, других средств защиты от воздействия опасных и вредных производственных факторов',
                        'Участвовать в разработке мероприятий по предупреждению профессиональных заболеваний и несчастных случаев на производстве, по улучшению условий труда и доведению их до требований нормативных правовых актов по охране труда, а также оказывать организационную помощь по выполнению разработанных мероприятий'
                    ],
                    requierments: [
                        'Высшее образование по специальности техносферная безопасность, трудоохраный менеджмент',
                        'Опыт работы от 1 года по специальности',
                        'Опыт работы инженером по охране труда от 6 месяцев',
                        'Уверенный пользователь ПК'
                    ],
                    conditions: [
                        'График работы 5/2 с 09.00 до 18.00',
                        'Возможны редкие командировки',
                        'Официальное трудоустройство',
                        'Заработная плата от 40 000 до 60 000 рублей (обсуждается индивидуально на собеседовании)',
                        'Карьерный и профессиональный рост в компании'
                    ],
                    keyskills: [
                    ],
                    open: false
                },
                {
                    name: 'Начальник участка строительного направления (Сургут)',
                    respon: [
                        'Подготовка строительного производства на участке строительства',
                        'Материально-техническое обеспечение строительного производства на участке строительства',
                        'Оперативное управление строительным производством на участке строительства',
                        'Приемка и контроль качества результатов выполненных видов и этапов строительных работ на участке строительства'
                    ],
                    requierments: [
                        'Высшее образование по специальности "Промышленное, гражданское строительство"',
                        'Работа в должности от 6 лет',
                        'Уверенный пользователь ПК (MS Office, Google таблицы)',
                        'Коммуникабельность',
                        'Стрессоустойчивость'
                    ],
                    conditions: [
                        'График работы: полный день, индивидуальный',
                        'Официальное трудоустройство',
                        'Карьерный и профессиональный рост в компании',
                        'Заработная плата от 80 000 до 100 000 рублей'
                    ],
                    keyskills: [
                    ],
                    open: false
                },
                {
                    name: 'Руководитель проекта строительного направления (Сургут)',
                    respon: [
                        'Определять устав, цели, задачи и результаты проекта',
                        'Продумывать и составлять план по подготовке и внедрению нового проекта, определять контрольные точки',
                        'Определять состав работ, необходимый для проработки и внедрению проекта',
                        'Определять и документировать зависимости между работами',
                        'Оценивать продолжительность работ, составлять критический путь',
                        'Определять количество времени, необходимого для осуществления проекта',
                        'Определять количество и оценивать стоимость ресурсов, требуемых для выполнения работ проекта',
                        'Оценивать стоимость и определять бюджет проекта',
                        'Выбирать команду проекта',
                        'Определять профессиональные навыки, необходимые участникам команды проекта'
                    ],
                    requierments: [
                        'Опыт работы от 3 до 6 лет',
                        'Исполнительность',
                        'Ответственность'
                    ],
                    conditions: [
                        'Официальное трудоустройство',
                        'Социальный пакет',
                        'Работа в офисе с 09:00 до 18.00',
                        'Заработная плата от 60 000 до 80 000 рублей'
                    ],
                    keyskills: [
                    ],
                    open: false
                }
            ]
        }

    ]

    const [checkvak, setCheckvak] = useState('')
    const [activemodal, setActivemodal] = useState(false)
    const [data, setData] = useState('')
    let totality = 0

    const calcTotal = () => {
        vakansii.forEach(pos=>{
            totality=totality+pos.positions.length
        })
        return totality
    }

    const [usevak, setUsevak] = useState(vakansii)

    const handlerPosition = (index) => {
        if(index + 1 > 0){
            let newlist = vakansii[index]
            let list = []
            list.push(newlist)
            setUsevak(list)
        }else{
            setUsevak(vakansii)
        }


    }

    const openPosition = (room, pos) => {
        const newlist = [...usevak]
        if(newlist[room].positions[pos].open === false){
            newlist[room].positions[pos].open = true
            const thisTit = document.getElementById(`tit${room}${pos}`)
            thisTit.lastChild.style.rotate = '90deg'
            console.log(thisTit.lastChild)
        }else{
            newlist[room].positions[pos].open = false
            const thisTit = document.getElementById(`tit${room}${pos}`)
            thisTit.lastChild.style.rotate = '0deg'
        }

        setUsevak(newlist)

    }

    const postResume = (pos) => {
        setData(pos)
        setActivemodal(true)
    }

    useEffect(()=>{
    })

    return (
        <div className={style.bodymain}>
            <WriteModal activemodal={activemodal} setActivemodal={setActivemodal} data={<PostResume man={data}  setActivemodal={setActivemodal}/>} setData={setData} />
            <SmallHeader/>
            <TwoBlocks/>
            <div className={style.vakansii}>
                <div className={style.container}>
                    <div className={style.title}>Вакансии</div>
                    <div className={style.block}>
                        <div className={style.leftpart}>
                            <div className={style.navigate}>
                                <div className={style.point} onClick={()=>handlerPosition()}>Все вакансии<div className={style.total}>{calcTotal()}</div></div>
                                {vakansii.map((point, index) => (
                                    <div key={index} className={style.point} onClick={()=>handlerPosition(index)}>{point.name}<div className={style.total}>
                                        {point.positions.length}
                                    </div></div>
                                ))}
                            </div>
                        </div>
                        <div className={style.rightpart}>
                            {usevak.map((com, index) => {
                                return (
                                    <div key={index} className={style.list}>
                                        {com.positions.map((pos, posIndex) => (
                                            <div key={posIndex} className={style.position}>
                                                <div className={style.title} id={`tit${index}${posIndex}`} onClick={()=>openPosition(index, posIndex)}>
                                                    <div className={style.text}>{pos.name}</div>
                                                    <div className={style.next}></div>
                                                </div>

                                                <div className={style.units} style={(pos.open)?{display:'flex'}:{}}>
                                                    <div className={style.name} style={(pos.requierments.length > 0)?{}:{display: 'none'}}>Требования</div>

                                                    {/* Список требований */}
                                                    {pos.requierments && pos.requierments.map((req, reqIndex) => (
                                                        <div key={reqIndex} className={style.points}>
                                                            <div className={style.slash}></div>
                                                            <div className={style.text}>{req}</div>
                                                        </div>
                                                    ))}

                                                    <div className={style.name} style={(pos.respon.length > 0)?{}:{display: 'none'}}>Обязанности</div>

                                                    {/* Список обязанностей */}
                                                    {pos.respon && pos.respon.map((res, resIndex) => (
                                                        <div key={resIndex} className={style.points}>
                                                            <div className={style.slash}></div>
                                                            <div className={style.text}>{res}</div>
                                                        </div>
                                                    ))}

                                                    <div className={style.name} style={(pos.conditions.length > 0)?{}:{display: 'none'}}>Условия</div>

                                                    {/* Список условий */}
                                                    {pos.conditions && pos.conditions.map((cond, condIndex) => (
                                                        <div key={condIndex} className={style.points}>
                                                            <div className={style.slash}></div>
                                                            <div className={style.text}>{cond}</div>
                                                        </div>
                                                    ))}
                                                    <div className={style.name} style={(pos.keyskills.length > 0)?{}:{display: 'none'}}>Ключевые навыки</div>

                                                    <div className={style.skillspoints}>
                                                        {pos.keyskills && pos.keyskills.map((skill, skillIndex) => (
                                                            <div key={skillIndex} className={style.keypoint}>{skill}</div>
                                                        ))}
                                                    </div>
                                                    <div className={style.btnresume} onClick={()=>postResume(pos)}>Откликнуться</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default AllNews